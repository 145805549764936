export default {
    cognito: {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_9E8x3OLUG",
      APP_CLIENT_ID: "47hs63ogitq6l1odt6rc1ucnmp"
    },
    apiGateway: {
      REGION: "eu-west-2",
      URL: "https://aojuwiinsb.execute-api.eu-west-2.amazonaws.com/dev"
    },
    app: {
      VERSION: "0.1.0"
    }
  };